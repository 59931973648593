<template>
  <div @mousewheel="goWheel($event)">
    <div class="homeCode">
      <div class="homeCode-child Mini">
        <span class="hover-line"></span>
        <img :src="Mini" alt="">
        <span>小程序</span>
        <div class="Mini-code">
          <img :src="MiniCode" alt="">
        </div>
      </div>
      <div class="homeCode-child Android">
        <span class="hover-line"></span>
        <img :src="Android" alt="">
        <span>安卓</span>
        <div class="Android-code">
          <img :src="androidDownLoadCode" alt="">
        </div>
      </div>
      <div class="homeCode-child Apple">
        <span class="hover-line"></span>
        <img :src="Apple" alt="">
        <span>IOS</span>
        <div class="Apple-code">
          <img :src="androidDownLoadCode" alt="">
        </div>
      </div>
    </div>
    <el-carousel :style="{height:swiperHeight}" indicator-position="none" ref="swiper" @change="change" :autoplay="false" :loop="false" direction="vertical" @mousewheel="goWheel($event)" class="introduce">
      <el-carousel-item class="introduceTop zIndex" v-lazy:background-image="topBg">
        <!-- <img v-lazy="topBg" alt=""> -->
      </el-carousel-item>
      <el-carousel-item class="introduceKnowledge dotStyleOne">
        <img v-lazy="knowledge" alt="" style="width:354PX;height:502PX">
        <div class="imgRight-text" style="margin-left:28PX">
          <div></div>
          <p>养宠知识小天地</p>
          <p>提供全方位的养宠知识，更专业、快速地</p>
          <p>帮助宠主建立科学养宠意识，了解更广泛</p>
          <p>的宠物知识</p>

        </div>
        <div class="dot1"></div>
        <div class="dot"></div>
      </el-carousel-item>
      <el-carousel-item class="introduceTop zIndex introduceHealth" v-lazy:background-image="bg">
        <div class="imgRight-text" style="margin-right:57PX">
          <div></div>
          <p>宠物健康问答</p>
          <p>平台免费提供一对一专业解答，</p>
          <p>最快的速度响应用户的提问。</p>
          <p>有问题，别担心，我们帮您解决</p>
        </div>
        <img v-lazy="health" alt="" style="width:529PX;height:420PX">

      </el-carousel-item>
      <el-carousel-item class="introduceDailyData dotStyleTwo">
        <div class="box">
          <div class="box-top">
            <div class="box-top-top">
              <p>日常数据记录</p>
              <p>DAILY DATA RECORD</p>
            </div>
            <div class="box-top-bottom">
              <p>记录宠物成长数据与日常时刻，一键查看让您对爱宠的情况了如指掌</p>

            </div>
          </div>
          <!-- <img v-lazy="dailyDataText" alt="" style="width:441PX;height:78PX"> -->
          <div class="accountList">
            <div class="accountList-item" v-for="(item,index) in dailyDataList" :key="index">
              <img :src="item.img" alt="" srcset="">
              <p>{{ item.text }}</p>
              <p>{{ item.span }}</p>
            </div>
          </div>
        </div>
        <!-- <img v-lazy="dailyData" alt="" style="width:991PX"> -->
        <div class="dot"></div>
        <div class="dot"></div>
      </el-carousel-item>
      <el-carousel-item class="introduceTop zIndex introduceHealth" v-lazy:background-image="bg">
        <div class="imgRight-text" style="margin-right:32PX">
          <div></div>
          <p>备忘录提醒</p>
          <p>宠主可创建提醒事项，系统将自动</p>
          <p>提醒,防止错过爱宠的重压时刻。提供</p>
          <p>智能推荐提醒，帮助宠主更好地科学养宠</p>
        </div>
        <img v-lazy="remind" alt="" style="width:563PX;height:521PX">
      </el-carousel-item>
      <el-carousel-item class="introduceDailyData dotStyleOne">
        <div class="box">
          <div class="box-top">
            <div class="box-top-top">
              <p>快速记账</p>
              <p>QUICK POSTING</p>
            </div>
            <div class="box-top-bottom">
              <p>快速记录爱宠日常花销，掌握每一笔花销情况</p>

            </div>
          </div>
          <div class="accountList">
            <div class="accountList-item" v-for="(item,index) in accountList" :key="index">
              <img :src="item.img" alt="" srcset="">
              <p>{{ item.text }}</p>
              <p>{{ item.span }}</p>
            </div>
          </div>
        </div>

        <div class="dot1"></div>
        <div class="dot"></div>
      </el-carousel-item>
      <el-carousel-item class="introduceTop zIndex introduceHealth" v-lazy:background-image="bg">
        <img v-lazy="share" alt="" style="width:303PX;height:335PX">
        <div class="imgRight-text" style="margin-left:94PX">
          <div></div>
          <p>共享账号</p>
          <p>选择宠物信息共享给家人和朋友，</p>
          <p>即可让亲友一同见证爱宠成长</p>
        </div>
      </el-carousel-item>
      <el-carousel-item class="introduceDailyData  dotStyleTwo">
        <div class="box">
          <div class="box-top">
            <div class="box-top-top">
              <p>健康档案</p>
              <p>HEALTH RECORDS</p>
            </div>
            <div class="box-top-bottom">
              <p>一键上传报告单记录检查数据，提供便捷的后期追溯路径</p>
            </div>
          </div>
          <div class="healthImg">
            <img v-lazy="report" alt="" style="width:743PX;height:405PX;">

          </div>
        </div>
        <div class="dot"></div>
        <div class="dot"></div>
      </el-carousel-item>

    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Mini: require('../../assets/home/index/Mini.png'),
      Android: require('../../assets/home/index/Android.png'),
      Apple: require('../../assets/home/index/Apple.png'),
      MiniCode: require('../../assets/home/MiniCode.png'),
      code: require('../../assets/home/code.png'),
      androidDownLoadCode: require('../../assets/home/index/androidDownLoadCode.png'),
      swiperIndex: 0,
      swiperHeight: document.body.clientHeight - 52 + 'PX',
      swiperWidth: document.body.clientWidth + 'PX',
      one: true,//以此来控制每次轮播图切换的张数
      topBg: this.$imgUrl + 'Introduce/topBg.png',
      bg: this.$imgUrl + 'Introduce/bg.png',
      knowledge: this.$imgUrl + 'Introduce/knowledge.png',
      health: this.$imgUrl + 'Introduce/health.png',
      // dailyData: require('Introduce/dailyData.png'),
      remind: this.$imgUrl + 'Introduce/remind.png',
      report: this.$imgUrl + 'Introduce/report.png',
      share: this.$imgUrl + 'Introduce/share.png',
      accountText: this.$imgUrl + 'Introduce/accountText.png',
      accountList: [
        {
          img: this.$imgUrl + 'Introduce/account1.png',
          text: '标准记账本',
          span: '宠物日常记账标配，随时随地记一笔'
        },
        {
          img: this.$imgUrl + 'Introduce/account2.png',
          text: '纯净记账',
          span: '功能丰富，操作简单，快捷记账'
        },
        {
          img: this.$imgUrl + 'Introduce/account3.png',
          text: '全新记一笔',
          span: '述宠专属升级版记一笔，平铺分类，方便快捷'
        },
        {
          img: this.$imgUrl + 'Introduce/account4.png',
          text: '极简操作',
          span: '3秒超快速记账，独立账本与共同账本一目了然'
        },
      ],
      dailyDataText: this.$imgUrl + 'Introduce/dailyDataText.png',
      dailyDataList: [
        {
          img: this.$imgUrl + 'Introduce/dailyData1.png',
          text: '体重记录',
          span: '提供多维度的数据记录，折线图直观对比爱宠变化'
        },
        {
          img: this.$imgUrl + 'Introduce/dailyData2.png',
          text: '驱虫记录',
          span: '提供常用的驱虫类型及品牌，可快速选择并记录'
        },
        {
          img: this.$imgUrl + 'Introduce/dailyData3.png',
          text: '疫苗记录',
          span: '提供疫苗类型及品牌的选择，系统自动帮助宠主鉴别疫苗真伪'
        },
        {
          img: this.$imgUrl + 'Introduce/dailyData4.png',
          text: '记事记录',
          span: '提供便捷的温馨时刻记录，随时查看爱宠的成长点滴'
        },
      ],

    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.swiperHeight = (window.fullHeight - 52) + 'PX'
      })()

    }
  },
  methods: {
    change(e) {
      this.swiperIndex = e
    },
    PX2rem(PX) {
      if (/%/ig.test(PX)) { // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
        return PX
      } else {
        return (parseFloat(PX) / 16) + 'rem' // 这里的37.5，和rootValue值对应
      }
    },
    goWheel(event) {
      if (event.deltaY > 0 && this.one == true) {
        this.$refs.swiper.next();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        // console.log(this.swiperIndex, "22222")
        if (this.swiperIndex == 7) {
          this.swiperIndex = 8
        }
        if (this.swiperIndex == 8) {
          this.$emit("goEnd", true)
        } else {
          this.$emit("goEnd", false)

        }
      }

      if (event.deltaY < 0 && this.one == true) {
        this.$refs.swiper.prev();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        this.$emit("goEnd", false)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.homeCode {
  z-index: 999;
  position: absolute;
  right: 30px;
  top: 102px;
  width: 38px;
  height: 225px;
  border-radius: 27px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;
  // box-shadow: 0 0 15px #a8ced4;
  box-shadow: 0 0 15px #a8ced4;

  .homeCode-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 21px;
      height: 21px;
    }

    span {
      color: #7bc8d2;
      font-size: 7px;
      font-family: SourceHanSansCN-Medium;
    }
  }

  .homeCode-child:hover {
    cursor: pointer;
  }

  .Mini {
    margin-top: 43px;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Mini-code {
      display: none;
      position: absolute;
      top: 20px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Mini:hover .Mini-code,
  .Mini:hover .hover-line {
    display: block;
  }
  .Android {
    margin: 30px 0;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Android-code {
      display: none;
      position: absolute;
      top: 80px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Android:hover .Android-code,
  .Android:hover .hover-line {
    display: block;
  }
  .Apple {
    margin-bottom: 43px;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Apple-code {
      display: none;
      position: absolute;
      top: 150px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Apple:hover .Apple-code,
  .Apple:hover .hover-line {
    display: block;
  }
}

// @media screen and (max-height: 758px) {
//   .accountList .accountList-item img {
//     height: 295px !important;
//   }
// }
img[lazy="loading"] {
  width: 400px !important;
  height: 50px !important;
}
.box {
  width: 990PX;
  display: flex;
  flex-direction: column;
  // @media screen and (max-height: 758PX) {
  //   .healthImg {
  //     margin-top: 23PX !important;
  //     img {
  //       height: 365PX !important;
  //     }
  //   }
  // }
  .healthImg {
    margin-top: 63PX;
    display: flex;
    justify-content: center;
    // justify-content: center;
  }
}
.box-top {
  .box-top-top {
    position: relative;
    p:first-child {
      font-size: 30PX;
      color: #333333;
      letter-spacing: 3PX;
      font-weight: bold;
      z-index: 2;
      position: relative;
    }
    p:nth-child(2) {
      color: #f2f2f1;
      font-size: 21PX;
      letter-spacing: 2PX;
      font-weight: bolder;
      position: absolute;
      top: 27PX;
    }
  }
  .box-top-bottom {
    margin-top: 28PX;
    p {
      font-size: 20PX;
      color: #7bc8d2;
      letter-spacing: 3PX;
      font-family: "SourceHanSansCN-Medium";
    }
  }
}
.accountList {
  display: flex;
  justify-content: center;
  margin-top: 44PX;
  .accountList-item:last-child {
    margin-right: 0PX;
  }
  .accountList-item {
    text-align: center;
    margin-right: 36PX;

    img {
      width: 220PX;
      height: 370PX;
    }
    p:nth-child(2) {
      font-size: 18PX;
      color: #000;
      margin-top: 22PX;
      font-family: "SourceHanSansCN-Medium";
    }
    p:nth-child(3) {
      font-size: 12PX;
      color: #000;
      margin-top: 10PX;
    }
  }
}
.introduceHealth {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgRight-text {
  div {
    width: 64PX;
    height: 3PX;
    background-color: #92d5de;
    border-radius: 2PX;
  }
  p {
    color: #999999;
    line-height: 26PX;
    letter-spacing: 2PX;
    font-size: 18PX;
    font-family: "SourceHanSansCN-Medium";
  }
  p:nth-child(2) {
    color: #333333;
    letter-spacing: 3.2PX;
    font-size: 32PX;
    margin-top: 22PX;
    margin-bottom: 20PX;
  }
}
/deep/ .el-carousel__container {
  height: 100% !important;
}
.introduce {
  // min-width: 1680PX;
  .zIndex {
    z-index: 50;
  }
  .imgZIndex {
    img {
      z-index: -1 !important;
    }
  }
  .introduceDailyData {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      // width: 1390PX;
      // height: 797PX;
      z-index: 50;
      position: relative;
    }
  }
  .introduceTop {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // position: relative;
    // overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-image: url("http://testshop.ichongapp.com/Introduce/bg.png");
    img {
      width: 100%;
      height: 100%;
      z-index: 50;
      position: relative;
    }
  }
  .introduceKnowledge {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // img {
    //   width: 687PX;
    //   height: 513PX;
    // }
  }
  .dotStyleOne {
    .dot1 {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 500PX;
      height: 500PX;
      left: -100PX;
      bottom: -200PX;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
    .dot:last-child {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 500PX;
      height: 500PX;
      right: -200PX;
      top: -200PX;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
  }

  .dotStyleTwo {
    .dot:nth-child(2) {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 450PX;
      height: 450PX;
      right: -200PX;
      bottom: -100PX;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
    .dot:last-child {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 450PX;
      height: 450PX;
      left: -200PX;
      top: -100PX;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
  }
}
@keyframes dot {
  0%,
  100% {
    transform: translateX(-5%) translateY(0);
  }
  25% {
    transform: translateX(0) translateY(-5%);
  }
  50% {
    transform: translateX(5%) translateY(0);
  }
  75% {
    transform: translateX(0) translateY(5%);
  }
}
</style>